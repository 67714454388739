import * as React from 'react';
import classNames from 'classnames';
import styles from './features.module.scss';
import Navbar from '../components/Navbar';
import Head from '../components/Head';
import Footer from '../components/Footer';
import LearnMore from '../components/LearnMore';

const FeaturesPage: React.FunctionComponent<{}> = () => {
  return (
    <div className={styles.FeaturesPage}>
      <Head title='Opinous | Features' />
      <Navbar />
      <div className={styles.Header}>
        <div className={styles.Content}>
          <h1>One solution to cover all your reputation needs</h1>
        </div>
        <svg className={styles.Crop} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 48'>
          <path
            fill='#FFF'
            fillRule='evenodd'
            d='M0 0c194.22 23.31 345.236 37.383 453.045 42.218C539.007 46.073 627.992 48 720 48c265.097 0 505.097-16 720-48v48H0V0z'
          />
        </svg>
      </div>
      <div className={styles.ProcessContainer}>
        <ul className={styles.Process}>
          <li>
            <a href='#gather'>Gather</a>
          </li>
          <li>
            <a href='#verify'>Verify</a>
          </li>
          <li>
            <a href='#publish'>Publish</a>
          </li>
          <li>
            <a href='#grow'>Grow</a>
          </li>
        </ul>
      </div>
      <div id='gather' className={styles.FeatureSection}>
        <div className={styles.FeatureHero}>
          <div className={styles.FeatureContent}>
            <h2>Gather feedback from your customers</h2>
            <p>
              Opinous makes it simple to gather feedback from all of your customers however you
              interact with them.
              <br />
              <br />
              We combine simple, one-click integrations with a beautiful form builder so you can
              collect the feedback that matters to you from every customer.
            </p>
            <ul className={styles.Features}>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Automated Email Invites
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                On-site Review Widgets
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Physical Review Cards
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Custom Fields & Ratings
              </li>
            </ul>
            <a className={styles.DemoRequest} href='https://manage.opinous.com/join'>
              Get Started <i className='fas fa-arrow-right' />
            </a>
          </div>
          <div className={styles.FeatureImage}>
            <div className={classNames(styles.Card, styles.FormFeatImg)} />
          </div>
        </div>
      </div>
      <div id='verify' className={classNames(styles.FeatureSection, styles.Reverse)}>
        <div className={styles.FeatureHero}>
          <div className={classNames(styles.FeatureImage, styles.Small)}>
            <div className={classNames(styles.Card, styles.VerifiedFeatImg)}>
              <div className={styles.Zoom} />
            </div>
          </div>
          <div className={styles.FeatureContent}>
            <h2>
              Verify reviews are from <strong>real</strong> customers
            </h2>
            <p>
              Your brand is best served by transparency. Opinous is dedicated to showing only honest
              and fair reviews.
              <br />
              <br />
              We provide a seamless toolkit to protect your brand from fake or malicious reviews
              both on our platform and around the internet on sites such as Google My Business and
              TripAdvisor.
            </p>
            <ul className={styles.Features}>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Response Templates
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Simple Dispute Process
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Google My Business Sync
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Automatic Verification
              </li>
            </ul>
            <a className={styles.DemoRequest} href='https://manage.opinous.com/join'>
              Get Started <i className='fas fa-arrow-right' />
            </a>
          </div>
        </div>
      </div>
      <div id='publish' className={styles.FeatureSection}>
        <div className={styles.FeatureHero}>
          <div className={styles.FeatureContent}>
            <h2>Easily display your reviews</h2>
            <p>
              Opinous provides a suite of simple, drop-in solutions that allow any business to
              improve it's digital reputation with reviews.
              <br />
              <br />
              Our collection of technical solutions enables anyone from a sole trader up to a public
              company to display reviews both on their own site and around the internet.
            </p>
            <ul className={styles.Features}>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Website Review Widget
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                CMA Reg. Compliance
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Google Search Metadata
              </li>
              <li>
                <span>
                  <i className='far fa-check' />
                </span>{' '}
                Repost to external sites
              </li>
            </ul>
            <a
              className={styles.DemoRequest}
              href='https://developers.opinous.com/reference/tags/displaykit/'
              target='_blank'
            >
              Read Documentation <i className='fas fa-arrow-right' />
            </a>
          </div>
          <div className={styles.FeatureImage}>
            <div className={classNames(styles.Card, styles.DisplayFeatImg)} />
          </div>
        </div>
      </div>
      <div id='grow' className={classNames(styles.FeatureSection, styles.Reverse, styles.Last)}>
        <div className={styles.FeatureHero}>
          <div className={classNames(styles.FeatureImage, styles.Small)}>
            <div className={classNames(styles.Card, styles.AcquireFeatImg)}>
              <div className={styles.Zoom} />
            </div>
          </div>
          <div className={styles.FeatureContent}>
            <h2>Acquire new customers and grow your business</h2>
            <p>
              Reviews are a crucial part of the modern SEO environment for local businesses.
              <br />
              <br />
              Opinous helps you to acquire new customers through digital channels as well as using
              consumer feedback to detect and improve on weaknesses in the experience you offer to
              customers.
            </p>
            <a className={styles.DemoRequest} href='https://manage.opinous.com/join'>
              Get Started <i className='fas fa-arrow-right' />
            </a>
          </div>
        </div>
      </div>

      <LearnMore />
      <Footer />
    </div>
  );
};

export default FeaturesPage;
